<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
    <div class="row">
      <div class="col-md-10">
        <h2 class="titulo-links-escolha">Configurações</h2>
      </div>
      <div class="col-md-2" >
        <pm-Button  type="button" @click="this.$router.push({name: 'calendario' });"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>
    <div class="pm-card" >
      <configuracao :id_calendario="id" />
    </div>
  </section>
</template>

<script>

import { defineComponent } from 'vue'
import configuracao from '@/components/configuracao/configuracao.vue'

export default defineComponent({
  props: {
    id:null,
  },
  components:{
    configuracao
  },
  data () {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Configuracão'},
      ],
      configuracao:{
        calendario_letivos_id:0,
        tipo_ano:null,
        tipo_ano_nome_exib:"Período",
        b1_inicio:null,
        b1_final:null,
        b2_inicio:null,
        b2_final:null,
        b3_inicio:null,
        b3_final:null,
        b4_inicio:null,
        b4_final:null,
      },
    }
  },
  async beforeMount() {},
  methods:{

  }
})

</script>

<style>

</style>
