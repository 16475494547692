<template>
  <section>

   <div class="field col-12 md:col-12 lg:col-12">
        <div v-if="fund.simulado_selec == '1' && fund.avaliacao_selec == '0' && fund.atividade_selec == '0'"  >
            <label>A soma do Simulado precisar ser igual a 100</label>
            <div v-if="fund.simulado == 100">
              <h5><strong>{{fund.simulado}}</strong> = <strong style="color: #100fd5;">{{fund.simulado}}</strong></h5>
            </div>
            <div v-else>
              <h5><strong>{{fund.simulado}}</strong> = <strong style="color: #e80c0c;">{{fund.simulado}}</strong></h5>
            </div>
        </div>
        <div  v-if="fund.simulado_selec == '0' && fund.avaliacao_selec == '0' && fund.atividade_selec == '1'"  >
            <label>A soma da Atividade precisar ser igual a 100</label>
            <div v-if="fund.atividades == 100">
              <h5><strong>{{fund.atividades}}</strong> = <strong style="color: #100fd5;">{{fund.atividades}}</strong></h5>
            </div>
            <div v-else>
              <h5><strong>{{fund.atividades}}</strong> = <strong style="color: #e80c0c;">{{fund.atividades}}</strong></h5>
            </div>
        </div>
        <div  v-if="fund.simulado_selec == '0' && fund.avaliacao_selec == '1' && fund.atividade_selec == '0'"  >
            <label>A soma da avaliação precisar ser igual a 100</label>
            <div v-if="fund.avaliacao == 100">
              <h5><strong>{{fund.avaliacao}}</strong> = <strong style="color: #100fd5;">{{fund.avaliacao}}</strong></h5>
            </div>
            <div v-else>
              <h5><strong>{{fund.avaliacao}}</strong> = <strong style="color: #e80c0c;">{{fund.avaliacao}}</strong></h5>
            </div>
        </div>
        <div v-if="fund.simulado_selec == '1' && fund.avaliacao_selec == '1' && fund.atividade_selec == '0'"  >
            <label>A soma do simulado e da avaliação precisar ser igual a 100</label>
            <div v-if="(fund.simulado + fund.avaliacao) == 100">
              <h5><strong>{{fund.simulado}}</strong> + <strong>{{fund.avaliacao}}</strong> = <strong style="color: #100fd5;">{{fund.simulado + fund.avaliacao}}</strong></h5>
            </div>
            <div v-else>
              <h5><strong>{{fund.simulado}}</strong> + <strong>{{fund.avaliacao}}</strong> = <strong style="color: #e80c0c;">{{fund.simulado + fund.avaliacao}}</strong></h5>
            </div>
        </div>
        <div v-if="fund.simulado_selec == '0' && fund.avaliacao_selec == '1' && fund.atividade_selec == '1'"  >
            <label>A soma da Atividade e Avaliação precisar ser igual a 100</label>
            <div v-if="(fund.atividades + fund.avaliacao) == 100">
              <h5><strong>{{fund.atividades}}</strong> + <strong>{{fund.avaliacao}}</strong> = <strong style="color: #100fd5;">{{fund.atividades + fund.avaliacao}}</strong></h5>
            </div>
            <div v-else>
              <h5><strong>{{fund.atividades}}</strong> + <strong>{{fund.avaliacao}}</strong> = <strong style="color: #e80c0c;">{{fund.atividades + fund.avaliacao}}</strong></h5>
            </div>
        </div>
        <div v-if="fund.avaliacao_selec == '1' && fund.simulado_selec == '1' && fund.atividade_selec == '1'">
            <label>A soma da Atividade, Simulado e Avaliação precisar ser igual a 100</label>
            <div v-if="(fund.simulado + fund.atividades + fund.avaliacao) == 100">
              <h5><strong>{{fund.simulado}}</strong> + <strong>{{fund.atividades}}</strong> + <strong>{{fund.avaliacao}}</strong> = <strong style="color: #100fd5;">{{fund.simulado + fund.atividades + fund.avaliacao}}</strong></h5>
            </div>
            <div v-else>
              <h5><strong>{{fund.simulado}}</strong> + <strong>{{fund.atividades}}</strong> + <strong>{{fund.avaliacao}}</strong> = <strong style="color: #e80c0c;">{{fund.simulado + fund.atividades + fund.avaliacao}}</strong></h5>
            </div>
        </div>
    </div>


  </section>
</template>

<script>
export default {
  name: "SomaNotas",
  props: {
    fund: { type: Array, default: () => [] }
  },
  data () {
    return {
      accordionValue: []
    }
  },
  mounted () {

  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

</style>
